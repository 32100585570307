import React, { useCallback } from 'react';
import classNames from 'classnames';
import { createClasses } from '@kp/react-ui';
import { Sidebar } from '../Sidebar';
import { Header } from '../Header';
import { SIDEBAR_CLOSED_WIDTH, SIDEBAR_OPENED_WIDTH } from '../../constants/UI';
import { PageHeader } from '../PageHeader';
import { useSidebar } from '../../contexts/sidebar-context';
import { Notifications } from '../Notifications';

const classes = createClasses({
  content: {
    color: '#000',
    flexGrow: 1,
    marginLeft: SIDEBAR_CLOSED_WIDTH,
    transition: `margin 0.2s`,
  },
  narrowContent: {
    marginLeft: SIDEBAR_OPENED_WIDTH,
    transition: `margin 0.2s`,
  },
  root: {
    display: 'flex',
  },
  simpleContent: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  body: {
    padding: '24px',
  },
});

interface LayoutProps {
  simple?: boolean;
  noBreadcrumb?: boolean;
  children: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({
  simple,
  noBreadcrumb,
  children,
}) => {
  const { open, setOpen } = useSidebar();

  const handleCollapseClick = useCallback(() => {
    setOpen(!open);
  }, [open, setOpen]);

  return (
    <div className={classes.root}>
      {!simple && (
        <Header onCollapseClick={handleCollapseClick} sidebarOpen={open} />
      )}
      {!simple && <Sidebar open={open} />}
      <div
        className={classNames(
          simple ? classes.simpleContent : classes.content,
          !simple && open && classes.narrowContent,
        )}
      >
        {!noBreadcrumb && <PageHeader />}
        <div className={classes.body}>{children}</div>
      </div>
      <Notifications />
    </div>
  );
};
