import React, { useCallback, useContext, createContext, useState } from 'react';
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect';

export interface BreadcrumbLink {
  title: string;
  location: string;
}

interface BreadcrumbContextValue {
  links: BreadcrumbLink[];
  add: (link?: BreadcrumbLink | BreadcrumbLink[]) => () => void;
}

const BreadcrumbContext = createContext<BreadcrumbContextValue>({
  links: [],
  add: () => () => {},
});

export const useBreadcrumb = (
  link?: BreadcrumbLink | BreadcrumbLink[],
): BreadcrumbLink[] => {
  const { add, links } = useContext(BreadcrumbContext);
  if (!links) {
    throw new Error(`useBreadcrumb must be used within a BreadcrumbProvider`);
  }
  useDeepCompareEffectNoCheck(() => add(link), [link]);
  return links;
};

export const BreadcrumbProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [allLinks, setLinks] = useState<BreadcrumbLink[]>([]);
  const add = useCallback(
    (link?: BreadcrumbLink | BreadcrumbLink[]) => {
      if (!link) {
        return () => {};
      }
      const links = Array.isArray(link) ? link : [link];
      setLinks((items) => [...items, ...links]);
      return () => {
        setLinks((items) => items.filter((item) => !links.includes(item)));
      };
    },
    [setLinks],
  );
  return (
    <BreadcrumbContext.Provider value={{ links: allLinks, add }}>
      {children}
    </BreadcrumbContext.Provider>
  );
};
