import React from 'react';
import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbLink,
  BreadcrumbRootLink,
  Colors,
  createClasses,
  Icons,
} from '@kp/react-ui';
import { useBreadcrumb } from '../../contexts/breadcrumb-context';

const classes = createClasses({
  root: {
    padding: '10px 24px',
    background: Colors.Neutral.background,
  },
});

export const Breadcrumbs: React.FC = () => {
  const links = useBreadcrumb();

  return (
    <>
      <Breadcrumb className={classes.root}>
        <BreadcrumbRootLink icon={Icons.IconPerson} component={Link} to="/" />
        {links.map((link, index) => {
          return link.location === '' ? (
            <BreadcrumbLink
              title={link.title}
              key={index}
              selected={index === links.length - 1}
            />
          ) : (
            <BreadcrumbLink
              title={link.title}
              key={index}
              component={Link}
              to={link.location}
              selected={index === links.length - 1}
            />
          );
        })}
      </Breadcrumb>
    </>
  );
};
