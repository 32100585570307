import { TFunction } from 'i18next';
import { Page } from './Pages';
import * as Pages from './Pages';

export const SIDEBAR_OPENED_WIDTH = 240;
export const SIDEBAR_CLOSED_WIDTH = 72;

interface SidebarSection {
  id: string;
  label: (t: TFunction) => string;
  items: Page[];
  showDevider?: 'before' | 'after';
}

export const SIDEBAR_SECTIONS: SidebarSection[] = [
  {
    id: 'personal',
    label: (t) => t('sidebar.section.personal'),
    items: [Pages.PERSONAL_INFO, Pages.CHANGE_PASSWORD],
  },
  {
    id: 'remove',
    label: (t) => t('sidebar.section.personal'),
    items: [Pages.DELETE_ACCOUNT],
    showDevider: 'before',
  },
];
