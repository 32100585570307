import React, { useEffect } from 'react';
import * as Yup from 'yup';
import {
  Card,
  Button,
  FormControl,
  DescriptionList,
  createClasses,
} from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { TextField } from '../../../components/fields';

const classes = createClasses({
  actionButton: {
    marginLeft: '12px',
  },
});

export interface UserData {
  username: string;
  email: string;
  firstName: string;
  lastName: string;
}

interface PersonalInfoEditProps extends UserData {
  loading?: boolean;
  onSubmit: (data: UserData) => void;
  onDiscard: () => void;
}

export const PersonalInfoEdit: React.FC<PersonalInfoEditProps> = ({
  username,
  email,
  firstName,
  lastName,
  loading,
  onSubmit,
  onDiscard,
}) => {
  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    username: Yup.string().required(
      t('personalInfo.edit.validation.username.required') as string,
    ),
    email: Yup.string().required(
      t('personalInfo.edit.validation.email.required') as string,
    ),
    firstName: Yup.string().required(
      t('personalInfo.edit.validation.firstName.required') as string,
    ),
    lastName: Yup.string().required(
      t('personalInfo.edit.validation.lastName.required') as string,
    ),
  });

  const defaultValues: UserData = {
    username,
    email,
    firstName,
    lastName,
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });
  const { handleSubmit, reset } = methods;
  useEffect(() => {
    reset({
      username,
      email,
      firstName,
      lastName,
    });
  }, [reset, username, email, firstName, lastName]);

  return (
    <FormProvider {...methods}>
      <Card
        title={t('personalInfo.basicInformation.title') as string}
        actions={
          <>
            <Button
              onClick={onDiscard}
              variant="tertiary"
              className={classes.actionButton}
              aria-label="discard-button"
            >
              {t('general.buttons.discard')}
            </Button>
            <Button
              onClick={handleSubmit(onSubmit)}
              className={classes.actionButton}
              variant="primary"
              aria-label="save-button"
              disabled={loading}
            >
              {t('general.buttons.save')}
            </Button>
          </>
        }
      >
        <FormControl>
          <DescriptionList
            term={t('personalInfo.basicInformation.username')}
            description={username}
          />
        </FormControl>
        <FormControl>
          <TextField
            id="email"
            name="email"
            type="text"
            label={t('personalInfo.basicInformation.email') || ''}
            fullWidth
            disabled={loading}
          />
        </FormControl>
        <FormControl>
          <TextField
            id="firstName"
            name="firstName"
            type="text"
            label={t('personalInfo.basicInformation.firstName') || ''}
            fullWidth
            disabled={loading}
          />
        </FormControl>
        <FormControl>
          <TextField
            id="lastName"
            name="lastName"
            type="text"
            label={t('personalInfo.basicInformation.lastName') || ''}
            fullWidth
            disabled={loading}
          />
        </FormControl>
      </Card>
    </FormProvider>
  );
};
