import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Card,
  createClasses,
  DescriptionList,
  FormControl,
  Icons,
} from '@kp/react-ui';
import { Language } from '../../../api/user';
import { languages } from '../common/constants';

const classes = createClasses({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  card: {
    width: 'calc( 50% - 12px )',
  },
});

interface PersonalInfoDetailsProps {
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  language: Language;
  onEdit: () => void;
  onEditSettings: () => void;
}

export const PersonalInfoDetails: React.FC<PersonalInfoDetailsProps> = ({
  username,
  email,
  firstName,
  lastName,
  language,
  onEdit,
  onEditSettings,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Card
        title={t('personalInfo.basicInformation.title') as string}
        className={classes.card}
        actions={
          <Button
            variant="tertiary"
            icon={Icons.IconEdit}
            data-testid="card-edit"
            onClick={onEdit}
          >
            {t('personalInfo.edit.action')}
          </Button>
        }
      >
        <FormControl>
          <DescriptionList
            term={t('personalInfo.basicInformation.username')}
            description={username}
          />
        </FormControl>
        <FormControl>
          <DescriptionList
            term={t('personalInfo.basicInformation.email')}
            description={email}
          />
        </FormControl>
        <FormControl>
          <DescriptionList
            term={t('personalInfo.basicInformation.firstName')}
            description={firstName}
          />
        </FormControl>
        <FormControl>
          <DescriptionList
            term={t('personalInfo.basicInformation.lastName')}
            description={lastName}
          />
        </FormControl>
      </Card>
      <Card
        title={t('personalInfo.userSettings.title') as string}
        className={classes.card}
        actions={
          <Button
            variant="tertiary"
            data-testid="card-edit"
            icon={Icons.IconEdit}
            onClick={onEditSettings}
          >
            {t('personalInfo.edit.action')}
          </Button>
        }
      >
        <FormControl>
          <DescriptionList
            term={t('personalInfo.userSettings.language.label')}
            description={languages(t)[language]}
          />
        </FormControl>
      </Card>
    </div>
  );
};
