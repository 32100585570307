import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ProtectedRoute } from '@kp/react-sdk';
import { Layout } from './components/Layout';
import { PersonalInfoDetailsContainer } from './features/personalInfo/details/PersonalInfoDetailsContainer';
import { PersonalInfoEditContainer } from './features/personalInfo/edit/PersonalInfoEditContainer';
import { PersonalInfoSettingsContainer } from './features/personalInfo/settings/PersonalInfoSettingsContainer';
import { NotFoundHandler } from './features/NotFoundHandler';

const defaultLayout: string[] = ['/edit', '/settings'];
const noBreadcumb: string[] = ['/edit', '/settings'];

const App: React.FC = () => {
  const location = useLocation();

  const isFullscreen =
    location.pathname !== '/' &&
    !defaultLayout.some((route) => location.pathname.endsWith(route));
  const hideBreadcumb =
    location.pathname !== '/' &&
    !noBreadcumb.some((route) => location.pathname.endsWith(route));

  return (
    <Layout simple={isFullscreen} noBreadcrumb={hideBreadcumb}>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute element={<PersonalInfoDetailsContainer />} />
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute element={<PersonalInfoSettingsContainer />} />
          }
        />
        <Route
          path="/edit"
          element={<ProtectedRoute element={<PersonalInfoEditContainer />} />}
        />
        <Route path="*" element={<NotFoundHandler />} />
      </Routes>
    </Layout>
  );
};

export default App;
