import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Auth } from '@kp/react-sdk';
import {
  initAppInsights,
  AppInsightsErrorBoundary,
} from '@kp/react-sdk/app-insights';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { injectGlobal } from '@kp/react-ui';
import { OpenAPI } from '@kp/rest-api-javascript-sdk';
import App from './App';
import './utils/i18n';
import { HeaderProvider } from './contexts/header-context';
import { BreadcrumbProvider } from './contexts/breadcrumb-context';
import { SidebarProvider } from './contexts/sidebar-context';
import {
  CLIENT_ID,
  AUTH_URI,
  APPLICATIONINSIGHTS_CONNECTION_STRING,
  REST_URI,
} from './utils/env';
import { SettingsProvider } from './contexts/settings-provider';
import { ErrorFallback } from './components/Errors';
import { Loader } from './components/Loader';
import { NotificationsProvider } from './contexts/notifications-context';
import { GenericPageProvider } from './contexts/generic-page-context';

injectGlobal({
  html: {
    boxSizing: 'border-box',
  },
  body: {
    margin: '0px',
    fontFamily: "'Fira Sans', sans-serif",
  },
  '*, *::before, *::after': {
    boxSizing: 'inherit',
  },
  ul: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  ol: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
});

if (APPLICATIONINSIGHTS_CONNECTION_STRING) {
  initAppInsights('account-app', {
    connectionString: APPLICATIONINSIGHTS_CONNECTION_STRING,
    correlationHeaderExcludedDomains: ['id.*'],
  });
}

const queryClient = new QueryClient();
// eslint-disable-next-line no-underscore-dangle
OpenAPI.TOKEN = async () => window.__AUTH_TOKEN__ || '';
OpenAPI.BASE = REST_URI;

const Root: React.FC = () => (
  <React.StrictMode>
    <Suspense fallback={<Loader />}>
      <QueryClientProvider client={queryClient}>
        <AppInsightsErrorBoundary
          FallbackComponent={ErrorFallback}
          onError={console.warn}
        >
          <Auth clientId={CLIENT_ID} url={AUTH_URI} LoadingComponent={Loader}>
            <SettingsProvider>
              <BreadcrumbProvider>
                <Router>
                  <NotificationsProvider>
                    <HeaderProvider>
                      <SidebarProvider>
                        <GenericPageProvider>
                          <App />
                        </GenericPageProvider>
                      </SidebarProvider>
                    </HeaderProvider>
                  </NotificationsProvider>
                </Router>
              </BreadcrumbProvider>
            </SettingsProvider>
          </Auth>
        </AppInsightsErrorBoundary>
      </QueryClientProvider>
    </Suspense>
  </React.StrictMode>
);

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<Root />);
