import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ApiError } from '@kp/rest-api-javascript-sdk';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import * as Entities from '../../../constants/Entities';
import { ErrorMessage } from '../../../components/Errors';
import { useHeader } from '../../../contexts/header-context';
import { PersonalInfoEdit, UserData } from './PersonalInfoEdit';
import { getAccount, updateAccount } from '../../../api/account';
import { useNotifications } from '../../../contexts/notifications-context';

export const PersonalInfoEditContainer: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setTitle } = useHeader();
  const { add } = useNotifications();

  useEffect(() => {
    setTitle(t('personalInfo.general.title'));
  }, [setTitle, t]);

  const { isLoading: loadingAccount, data: userData } = useQuery({
    queryKey: ['getAccount'],
    queryFn: () => getAccount(),
    onError: ({ body: { message } }: ApiError) => {
      message &&
        add({
          type: 'danger',
          id: message,
          content: message,
        });
    },
  });

  const { mutate: update, isLoading: loadingUpdate } = useMutation({
    mutationFn: updateAccount,
    onSuccess: () => {
      navigate(`${Entities.PERSONAL_INFO.path}`);
    },
    onError: ({ body: { message } }: ApiError) => {
      message &&
        add({
          type: 'danger',
          id: message,
          content: message,
        });
    },
  });

  useBreadcrumb([
    { title: t('personalInfo.general.title'), location: '' },
    { title: t('personalInfo.edit.breadcrumb'), location: '' },
  ]);

  const handleUpdate = (data: UserData) => {
    const newUserData = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
    };
    return update(newUserData);
  };
  const handleCancel = () => {
    navigate(`${Entities.PERSONAL_INFO.path}`);
  };

  if (!loadingAccount && !userData) {
    return (
      <ErrorMessage
        error={new Error(t('personalInfo.error.accountNotFound') as string)}
      />
    );
  }

  return (
    <PersonalInfoEdit
      loading={loadingAccount || loadingUpdate}
      username={userData?.username || ''}
      email={userData?.email || ''}
      firstName={userData?.firstName || ''}
      lastName={userData?.lastName || ''}
      onDiscard={handleCancel}
      onSubmit={handleUpdate}
    />
  );
};
