export interface Entity {
  debugKey: string;
  path: string;
  parent?: Entity;
}

export const PERSONAL_INFO: Entity = {
  debugKey: 'PersonalInfo',
  path: `/`,
};
