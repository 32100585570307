import urljoin from 'url-join';
import { AUTH_URI } from '../../utils/env';

export const url = (segment?: string): string =>
  urljoin(AUTH_URI, '/realms/kp/account', segment || '');

export enum UserInvitationStatus {
  Pending = 'pending',
  Accepted = 'accepted',
}

export type Account = {
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  emailVerified: boolean;
  attributes: {
    [Key: string]: string[];
  };
};
export type AccountUpdateOptions = Partial<
  Pick<Account, 'email' | 'firstName' | 'lastName' | 'attributes'>
>;

/**
 * Get the personal information
 *
 */
export const getAccount = async (): Promise<Account> => {
  // eslint-disable-next-line no-underscore-dangle
  const token = window.__AUTH_TOKEN__;

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: token ? `Bearer ${token}` : '',
  };

  return fetch(url(''), { headers }).then((res) => res.json());
};

/**
 * Update the personal information
 *
 */
export const updateAccount = (
  account: AccountUpdateOptions,
): Promise<undefined> => {
  // eslint-disable-next-line no-underscore-dangle
  const token = window.__AUTH_TOKEN__;

  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: token ? `Bearer ${token}` : '',
  };

  return fetch(url(''), {
    method: 'POST',
    headers,
    body: JSON.stringify(account),
  }).then(() => undefined);
};
