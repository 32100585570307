import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { ApiError } from '@kp/rest-api-javascript-sdk';
import { useQuery } from '@tanstack/react-query';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import { ErrorMessage } from '../../../components/Errors';
import { useHeader } from '../../../contexts/header-context';
import { PersonalInfoDetails } from './PersonalInfoDetails';
import { getAccount } from '../../../api/account';
import { getUserSettings, Language } from '../../../api/user';
import { useNotifications } from '../../../contexts/notifications-context';

export const PersonalInfoDetailsContainer: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setTitle } = useHeader();
  const { add } = useNotifications();

  useEffect(() => {
    setTitle(t('personalInfo.page'));
  }, [setTitle, t]);

  const { isLoading: loadingAccount, data: userData } = useQuery({
    queryKey: ['getAccount'],
    queryFn: () => getAccount(),
    onError: ({ body: { message } }: ApiError) => {
      message &&
        add({
          type: 'danger',
          id: message,
          content: message,
        });
    },
  });

  const { isLoading: loadingUserSettings, data: responseUserSettings } =
    useQuery({
      queryKey: ['getUserSettings'],
      queryFn: () => getUserSettings(),
      onError: ({ body: { message } }: ApiError) => {
        message &&
          add({
            type: 'danger',
            id: message,
            content: message,
          });
      },
    });

  const userSettings = responseUserSettings?.data;

  useBreadcrumb([]);

  const handleEdit = useCallback(() => {
    navigate(`/edit`);
  }, [navigate]);

  const handleEditSettings = useCallback(() => {
    navigate(`/settings`);
  }, [navigate]);

  if (!loadingAccount && !userData) {
    return (
      <ErrorMessage
        error={new Error(t('personalInfo.error.accountNotFound') as string)}
      />
    );
  }
  if (!loadingUserSettings && !userSettings) {
    return (
      <ErrorMessage
        error={
          new Error(t('personalInfo.error.userSettingsNotFound') as string)
        }
      />
    );
  }

  return (
    <>
      <PersonalInfoDetails
        username={userData?.username || '-'}
        email={userData?.email || '-'}
        firstName={userData?.firstName || '-'}
        lastName={userData?.lastName || '-'}
        onEdit={handleEdit}
        onEditSettings={handleEditSettings}
        language={(userSettings?.language as Language) || Language.en}
      />
    </>
  );
};
