import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@kp/react-sdk';
import {
  SidebarItem,
  Sidebar as Component,
  SidebarDivider,
  createClasses,
} from '@kp/react-ui';
import { NavLink, NavLinkProps } from 'react-router-dom';
import { SIDEBAR_SECTIONS } from '../../constants/UI';
import { getAction } from '../../api/actions';

const classes = createClasses({
  root: {
    // raises the sidebar tooltip above layout content
    zIndex: 99,
  },
});

const SidebarLink = React.forwardRef<
  HTMLAnchorElement,
  Omit<NavLinkProps, 'className' | 'children'> & {
    className?: string;
    children?: React.ReactNode;
    activeClassName?: string;
  }
>(({ className, activeClassName, ...props }, ref) => (
  <NavLink
    ref={ref}
    className={({ isActive }) =>
      classNames(className, isActive && activeClassName)
    }
    {...(props as NavLinkProps)}
  />
));

SidebarLink.displayName = 'SidebarLink';

interface SidebarProps {
  open?: boolean;
}

export const Sidebar: React.FC<SidebarProps> = ({ open }) => {
  const { hasPermission, authClient } = useAuth();
  const { t } = useTranslation();

  const sections = useMemo(() => {
    return SIDEBAR_SECTIONS.map(({ items, ...rest }) => {
      return {
        items: items.filter((item) =>
          item?.permission ? hasPermission(item.permission) : true,
        ),
        ...rest,
      };
    });
  }, [hasPermission]);

  return (
    <Component open={open} className={classes.root}>
      {sections.map(
        ({ id, items, showDevider }) =>
          items.length > 0 && (
            <div key={id}>
              {showDevider === 'before' && <SidebarDivider />}
              {items.map(({ label, icon, path, action }) => (
                <SidebarItem
                  key={label(t)}
                  component={SidebarLink}
                  to={path || '/'}
                  icon={icon}
                  onClick={getAction(action, { t, authClient })}
                  selected={path === '/'}
                  title={label(t)}
                />
              ))}
              {showDevider === 'after' && <SidebarDivider />}
            </div>
          ),
      )}
    </Component>
  );
};
