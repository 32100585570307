import React, { useCallback } from 'react';
import {
  Header as UIHeader,
  IconButton,
  Popover,
  Menu,
  MenuItem,
  Avatar,
  Colors,
  Icons,
  LogoB2B,
  createClasses,
} from '@kp/react-ui';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@kp/react-sdk';
import { ADMIN_APP_URI, BUILDING_INSIGHTS_APP_URI } from '../../utils/env';

const classes = createClasses({
  header: {
    position: 'fixed',
    zIndex: 120,
  },
  leftActions: {
    flexShrink: 1,
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    webkitFontSmoothing: 'antialiased',
    fontFamily: 'Inter',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: Colors.Neutral.text,
    marginLeft: '16px',
  },
  rightActions: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '8px',
    gap: '4px',
  },
  avatar: {
    marginLeft: '6px',
  },
  open: {
    transform: `rotate(180deg)`,
  },
  logo: {
    position: 'absolute',
    left: 'calc(50% - 100px)',
  },
});

interface HeaderProps {
  onCollapseClick?: () => void;
  sidebarOpen?: boolean;
}

export const Header: React.FC<HeaderProps> = ({
  onCollapseClick,
  sidebarOpen,
}) => {
  const { signOut, user } = useAuth();
  const { t } = useTranslation();

  const handleSignOut = useCallback(() => {
    signOut();
  }, [signOut]);

  const [firstName, lastName] = (user?.info?.name || '').split(/ /);

  return (
    <UIHeader className={classes.header}>
      <div className={classes.leftActions}>
        <IconButton
          icon={Icons.IconCollapseMenu}
          onClick={onCollapseClick}
          className={sidebarOpen ? undefined : classes.open}
        />
        <div className={classes.title}>Account</div>
      </div>
      <LogoB2B data-testid="logo" className={classes.logo} />
      <div className={classes.rightActions}>
        <IconButton
          component="a"
          href={ADMIN_APP_URI}
          target="_blank"
          data-testid="settings"
          icon={Icons.IconSettings}
        />
        <Popover
          data-testid="popover"
          trigger={IconButton}
          icon={Icons.IconApps}
          triggerActiveProp="active"
        >
          <Menu>
            <MenuItem
              component="a"
              href={BUILDING_INSIGHTS_APP_URI}
              target="_blank"
            >
              Building Insights
            </MenuItem>
          </Menu>
        </Popover>
        <Avatar
          data-testid="avatar"
          firstName={firstName || ''}
          lastName={lastName || ''}
          email={user?.info?.email || ''}
          className={classes.avatar}
        >
          <Menu>
            <MenuItem onClick={handleSignOut}>{t('header.signOut')}</MenuItem>
          </Menu>
        </Avatar>
      </div>
    </UIHeader>
  );
};
