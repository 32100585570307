import { createClasses } from '@kp/react-ui';
import React from 'react';

const classes = createClasses({
  errorMessage: {
    color: 'white',
    backgroundColor: 'red',
    padding: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 50,
  },
});

export type ErrorMessageProps = {
  error: Error;
};
export const ErrorMessage: React.FC<ErrorMessageProps> = ({ error }) => {
  // TODO: specific error messages? and translations
  const { message } = error;
  return (
    <div className={classes.errorMessage} aria-label="error-message">
      {message}
    </div>
  );
};
