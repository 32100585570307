import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ApiError, UserSvcUserSetting } from '@kp/rest-api-javascript-sdk';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useBreadcrumb } from '../../../contexts/breadcrumb-context';
import * as Entities from '../../../constants/Entities';
import { ErrorMessage } from '../../../components/Errors';
import { useHeader } from '../../../contexts/header-context';
import { PersonalInfoSettings, SettingsData } from './PersonalInfoSettings';
import {
  getUserSettings,
  updateUserSettings,
  Language,
} from '../../../api/user';
import { useNotifications } from '../../../contexts/notifications-context';

export const PersonalInfoSettingsContainer: React.FC = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { setTitle } = useHeader();
  const { add } = useNotifications();

  useEffect(() => {
    setTitle(t('personalInfo.userSettings.title'));
  }, [setTitle, t]);

  const { isLoading: loadingUserSettings, data: responseUserSettings } =
    useQuery({
      queryKey: ['getUserSettings'],
      queryFn: getUserSettings,
      onError: ({ body: { message } }: ApiError) => {
        message &&
          add({
            type: 'danger',
            id: message,
            content: message,
          });
      },
    });

  const { mutate: update, isLoading: loadingUpdate } = useMutation({
    mutationFn: updateUserSettings,
    onSuccess: (res: { data?: UserSvcUserSetting }) => {
      navigate(`${Entities.PERSONAL_INFO.path}`);
      if (res?.data?.language) {
        i18n.changeLanguage(res.data.language);
      }
    },
    onError: ({ body: { message } }: ApiError) => {
      message &&
        add({
          type: 'danger',
          id: message,
          content: message,
        });
    },
  });

  useBreadcrumb([
    { title: t('personalInfo.userSettings.title'), location: '' },
    { title: t('personalInfo.edit.breadcrumb'), location: '' },
  ]);

  const handleSubmit = useCallback(
    (data: SettingsData) => update({ language: data.language }),
    [update],
  );

  const handleDiscard = useCallback(() => {
    navigate(`${Entities.PERSONAL_INFO.path}`);
  }, [navigate]);

  if (!loadingUserSettings && !responseUserSettings) {
    return (
      <ErrorMessage
        error={
          new Error(t('personalInfo.error.userSettingsNotFound') as string)
        }
      />
    );
  }

  return (
    <PersonalInfoSettings
      loading={loadingUserSettings || loadingUpdate}
      language={
        (responseUserSettings?.data?.language as Language) || Language.en
      }
      onDiscard={handleDiscard}
      onSubmit={handleSubmit}
    />
  );
};
