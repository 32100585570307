import React from 'react';
import { Colors, createClasses, Typography } from '@kp/react-ui';
import { useHeader } from '../../contexts/header-context';
import { Breadcrumbs } from '../Breadcrumbs';

const classes = createClasses({
  root: {
    margin: 0,
    position: 'relative',
    paddingTop: '55px',
  },
  title: {
    padding: '9px 24px',
    borderTop: `1px solid ${Colors.Neutral.borderStrong}`,
    borderBottom: `1px solid ${Colors.Neutral.borderStrong}`,
    background: Colors.Neutral.background,
  },
});

export const PageHeader: React.FC = () => {
  const { title } = useHeader();

  return (
    <div className={classes.root}>
      <Breadcrumbs />
      <div className={classes.title}>
        <Typography variant="titleLarge">{title}</Typography>
      </div>
    </div>
  );
};
