import { TFunction } from 'i18next';
import queryString from 'query-string';
import type { useAuth } from '@kp/react-sdk';
import { B2B_SUPPORT_EMAIL } from '../../utils/env';

type Keycloak = ReturnType<typeof useAuth>['authClient'];

export enum Actions {
  UPDATE_PASSWORD,
  DELETE_ACCOUNT,
}

const updatePasswordAction = (keycloak: Keycloak) => () =>
  keycloak && keycloak.login({ action: 'UPDATE_PASSWORD' });

const deleteAccountAction = (t: TFunction) => () => {
  const params = queryString.stringify({
    subject: t('deleteAccount.email.subject').toString(),
    body: t('deleteAccount.email.body').toString(),
  });
  window.open(`mailto:${B2B_SUPPORT_EMAIL}?${params}`);
};

interface ActionOptions {
  t: TFunction;
  authClient: Keycloak;
}

export const getAction = (
  action: Actions | undefined,
  options: ActionOptions,
): (() => void) | undefined => {
  switch (action) {
    case Actions.UPDATE_PASSWORD:
      return updatePasswordAction(options.authClient);
    case Actions.DELETE_ACCOUNT:
      return deleteAccountAction(options.t);
    default:
      return undefined;
  }
};
