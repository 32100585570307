import type { TFunction } from 'i18next';
import type { Permissions } from '@kp/react-sdk';
import { Icons } from '@kp/react-ui';
import { Icon } from '@kp/react-ui/dist/cjs/__generated__/icons';
import { Actions } from '../api/actions';

export interface Page {
  label: (t: TFunction) => string;
  icon: Icon;
  path?: string;
  childPaths?: string[];
  permission?: Permissions;
  action?: Actions;
}

export const PERSONAL_INFO: Page = {
  label: (t) => t('personalInfo.page'),
  icon: Icons.IconPerson,
  path: '/',
  childPaths: ['edit', 'settings'],
};

export const CHANGE_PASSWORD: Page = {
  label: (t) => t('changePassword.page'),
  icon: Icons.IconLock,
  action: Actions.UPDATE_PASSWORD,
};

export const TWO_FACTOR: Page = {
  label: (t) => t('twoFactor.page'),
  icon: Icons.IconSearch,
  path: '/two-factor',
};

export const DELETE_ACCOUNT: Page = {
  label: (t) => t('deleteAccount.page'),
  icon: Icons.IconTrash,
  action: Actions.DELETE_ACCOUNT,
};
